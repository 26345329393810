<template>
  <div class="flex flex-col lg:flex-row gap-x-12">
    <div class="w-full lg:w-2/3">
      <div class="bg-white p-6 pt-8 sm:p-8 sm:pt-12 md:p-12 md:pt-16">
        <div class="text-content">
          <h1 class="flex flex-col">
            <span v-text="$t('title.why_astral_theme')" />
            <span
              class="bg-red-500 h-px inline-block w-16 mt-3"
              style="height: 2px"
            />
          </h1>
          <p>
            <img src="/img/astral-theme.jpg" alt="" loading="lazy" />
          </p>

          <div v-html="$t('page.why_astral_theme.text')" />
        </div>
      </div>
    </div>
    <div class="w-full lg:w-1/3 lg:pl-5">
      <div class="sticky top-0 pt-8">
        <sidebar-cta :ctaText="$t('title.get_started_3')" />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarCta from "../../components/SidebarCta.vue";
export default {
  components: { SidebarCta },
  name: "WhyTheAstralTheme",
  metaInfo() {
    return { title: this.$t("title.why_astral_theme") };
  },
};
</script>
